import axios from 'axios';
import wx from 'weixin-js-sdk'

export default {
  wxShowMenu(data: any) {
    // console.log(data);
    // let formData = new FormData();
    // formData.append('urls', data.link);
    // axios.post( data.ajaxUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(( res )=> {
      
    axios.get( data.ajaxUrl).then(( res ) => {
      const getMsg = res.data;
      // console.log(getMsg);
      wx.config({
        beta: false,
        debug: false,
        appId: getMsg.appId, // 必填，公众号的唯一标识
        timestamp: getMsg.timestamp, // 必填，生成签名的时间戳
        nonceStr: getMsg.nonceStr, // 必填，生成签名的随机串
        signature: getMsg.signature,// 必填，签名
        jsApiList: [
          // 'onMenuShareTimeline',//分享到朋友圈
          // 'onMenuShareAppMessage',//分享到朋友
          'updateAppMessageShareData',
          'updateTimelineShareData',
        ] // 必填，需要使用的JS接口列表
      })
      wx.ready(function() {

        //分享到朋友圈

        // wx.onMenuShareTimeline({

        //   title: data.titles, // 分享标题

        //   desc: data.descs, //分享描述

        //   link: data.link, // 分享链接

        //   imgUrl: data.imgUrl // 分享图标

        // });

        wx.updateTimelineShareData({
          title: data.titles, // 分享标题
          link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function () {
            // 设置成功
            // console.log('updateTimelineShareData:ok');
          },
          fail: function() {
            // console.log('updateTimelineShareData:false');
          }
        })



        //分享给朋友

        // wx.onMenuShareAppMessage({

        //   title: data.titles_circle, // 分享标题

        //   desc: data.descs, //分享描述

        //   link: data.link, // 分享链接

        //   imgUrl: data.imgUrl // 分享图标

        // });

        wx.updateAppMessageShareData({
          title: data.titles_circle, // 分享标题
          desc: data.descs, // 分享描述
          link: data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function () {
            // 设置成功
            // console.log('updateAppMessageShareData:ok');
          },
          fail: function() {
            // console.log('updateAppMessageShareData:false');
          }
        })

      });
    })
  }
}